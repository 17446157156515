import cx from 'classnames';
import audio from '~/../public/audio-example.webp';
import backdrop from '~/../public/backdrop-example.webp';
import chat from '~/../public/chat.webp';
import face from '~/../public/face-ar-example.webp';
import share from '~/../public/share.webp';
import tabletop from '~/../public/tabletop.webp';
import Image from '~/views/components/image';
import typography from '~/views/styles/typography.module.css';
import styles from './index.module.css';
const Section3 = () => <div className={styles.root}>
    <div className={styles.inner}>
      <header>
        <h2 className={cx(styles.h2, typography.h2)}>
          <strike>Play</strike> Experience tabletop role-playing games online
        </h2>
        <p className={cx(typography.body1, styles.subheader)}>
          SendingStone gives a complete set of tools to immerse parties into
          virtual worlds.
        </p>
      </header>
      <ul className={typography.body2}>
        <li>
          <div className={styles.exampleContainer}>
            <Image src={face} className={styles.arExample} width={308} alt='Elf AR face mask' />
          </div>
          <strong>AR face masks</strong>
          <p>
            Become an elven mage with the click of a button using built-in AR
            masks that use face-tracking to transform players into the character
            of their choice.
          </p>
        </li>
        <li>
          <div className={styles.exampleContainer}>
            <Image src={audio} alt='Screenshot of audio player' />
          </div>
          <strong>Audio ambience</strong>
          <p>
            Full control of audio mixing with the ability to play a single
            bard’s ballad, or overlay multiple tracks of rain storms, creature
            sounds, and battle drums.
          </p>
        </li>
        <li>
          <div className={styles.exampleContainer}>
            <Image src={backdrop} width={284} alt='Fairy forest backdrop' />
          </div>
          <strong>Multi-dimensional backdrops</strong>
          <p>
            The game master has the ability to control visually compelling
            backdrops at any time as their party adventures into a magical
            forest, snowy winter, or creepy night fall.
          </p>
        </li>
      </ul>
      <ul className={typography.body2}>
        <li>
          <div className={styles.exampleContainer}>
            <Image src={tabletop} alt='Screenshot of tabletop' />
          </div>
          <strong>Built-in tabletop</strong>
          <p>
            SendingStone has a simple real-time multiplayer tabletop. Tokens,
            pencils, shapes, images, videos, notes, live cursors, rulers, and
            more make switching from role-play to encounters frictionless.
          </p>
        </li>
        <li>
          <div className={styles.exampleContainer}>
            <Image src={share} className={styles.arExample} width={304} alt='Screenshare of a fairy' />
          </div>
          <strong>Seamless screen-share</strong>
          <p>
            Have a picture of your home-brewed fantasy creature or a custom
            world map to show your party? Share your screen at any point during
            your game without breaking the party’s immersion.
          </p>
        </li>
        <li>
          <div className={styles.exampleContainer}>
            <Image src={chat} className={styles.arExample} alt='Screenshot of chat feature' />
          </div>
          <strong>Maintain the table’s voice</strong>
          <p>
            Avoid the classic cross-talk and background noise from video
            conferencing with the game master’s ability to mute players, or keep
            the conversation quietly going through live group chat.
          </p>
        </li>
      </ul>
    </div>
  </div>;
export default Section3;