import cx from 'classnames';
import getStarted from '~/../public/get-started-steps.webp';
import Image from '~/views/components/image';
import layout from '~/views/styles/layout.module.css';
import typography from '~/views/styles/typography.module.css';
import styles from './index.module.css';
const Section4 = () => <div className={cx(styles.root, layout.gridWrapper)}>
    <div className={styles.left}>
      <h2 className={cx(styles.h2, typography.h2)}>Challenge rating zero</h2>
      <div className={cx(styles.p, typography.body1)}>
        SendingStone makes it easy to start a game:
        <ul>
          <li>
            <strong>Step 1.</strong>Click a button to become the game master.
          </li>
          <li>
            <strong>Step 2.</strong>Share the URL to gather your party.
          </li>
          <li>
            <strong>
              <strike>Step 3.</strike>
            </strong>
            There’s no step 3—venture forth!
          </li>
        </ul>
      </div>
    </div>
    <div className={styles.right}>
      <Image src={getStarted} width={272} alt='Illustration of open room button, copy URL, and a party venturing forth' />
    </div>
  </div>;
export default Section4;