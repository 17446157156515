import cx from 'classnames';
import layout from '~/views/styles/layout.module.css';
import typography from '~/views/styles/typography.module.css';
import { useIsScrolledDown } from '../../../../lib/utils';
import styles from './index.module.css';
const Section1 = () => {
  const isPlayVideo = useIsScrolledDown();
  return <div className={cx(layout.gridWrapper, styles.root)}>
      <div className={styles.browser}>
        <header>
          <div className={styles.browserDot} />
          <div className={styles.browserDot} />
          <div className={styles.browserDot} />
        </header>
        <div className={styles.browserInner}>
          {isPlayVideo && <video width='400' height='300' muted loop autoPlay playsInline>
              <source src='demo.mp4' type='video/mp4' />
            </video> || <img src='data:image/webp;base64,UklGRsYBAABXRUJQVlA4ILoBAADwCACdASooAB4APp0+l0gloyIhMBqqqLATiWMAytTIpJvgjW7NXKWhoEMIOruYkGKb+Vv6YXGWHFuWJ5HX2+R822+MKMfbeWTQzdeaXhFb8AD+wdr9f8e1Ii1DkFbF4XmiSvnc8Bs3wbhzyjKO28sSs7O55x449KohmMLw13nFabn+vKdfMuvf4QNUnq7fMQpN57MKzkzOU4ywr+ilh4ERvIOLH73mg2b6M8mDlBiKMnLO5ehooeAdM1n7OijOuZ8aUORumfQ75YKfDS+0rkW60n/xYmJRdVMb1kg03qR0VJ2dtpUNjOvfzimtYBIeVc3rSKT6fvmA6bX1Lu6bo3y05M0oW+yR3xTO0Oo7Gjez+qQgdi9H/5dn9nfE2hfscuzpLBySU+d6PXohObHXf241pcnYdgE3do7Tu6TzS+OM5kBs4+v8FDMcerWN02HuvZQnkCaE9oOnZPrlz4PA0vU304DPVy+bgDPTr9X3yrCNmMy2IeQqjNQ9I/8mOFZ4ECsZ5XJwijA9pW0QjfI10qRo1xNMPkRUUPEGsbbHGAkEX9wH5uk6qC0wBYUTMqxNFTD0PJuhCKYCzoAA' width={364} height={272} alt='Demo video placeholder' style={{
          width: '100%',
          filter: 'blur(16px)'
        }} />}
        </div>
      </div>

      <div className={cx(typography.body1, styles.p)}>
        <h2 className={cx(styles.h2, typography.h2)}>Adventure awaits</h2>
        Play tabletop role-playing games like Dungeons & Dragons with your
        friends online.
        <br />
        <br />
        SendingStone provides an immersive video conferencing experience worthy
        of fantasy role-playing.
      </div>
    </div>;
};
export default Section1;