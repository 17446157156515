'use client';

import hero from '~/../public/home-hero.webp';
import CtaButton from '../../cta-button';
import Footer from '../../footer';
import Head from '../../head';
import Hero from '../../hero';
import Nav from '../../nav';
import Section1 from '../section1';
import Section2 from '../section2';
import Section3 from '../section3';
import Section4 from '../section4';
import styles from './index.module.css';
const Main = () => {
  return <div className={styles.root}>
      <Head />
      <Nav />
      <Hero text='Play tabletop RPGs anywhere' backgroundImage={hero}>
        <CtaButton />
      </Hero>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>;
};
export default Main;