import cx from 'classnames';
import getStarted from '~/../public/one-app-compare.webp';
import Image from '~/views/components/image';
import layout from '~/views/styles/layout.module.css';
import typography from '~/views/styles/typography.module.css';
import CtaButton from '../../cta-button';
import styles from './index.module.css';
const Section2 = () => <div className={cx(styles.root, layout.gridWrapper)}>
    <div className={styles.left}>
      <h2 className={cx(styles.h2, typography.h2)}>
        One web-app to rule them all
      </h2>
      <div className={cx(typography.body1, styles.p)}>
        <ul>
          <li>No downloads.</li>
          <li>No player login walls.</li>
          <li>No more patchwork of apps.</li>
        </ul>
        SendingStone brings together all the features you need to play tabletop
        role-playing games online in one simple browser-based app.
      </div>
      <CtaButton />
    </div>
    <div className={styles.right}>
      <Image src={getStarted} alt='Illustration of logos of Roll20, Discord, etc. vs. SendingStone' />
    </div>
  </div>;
export default Section2;